

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { FormControlType } from '@/model/form-control';
import { ViewModeType } from '@/model/enum';
import { ICRUDQ } from '@/model/interface';
import LabelService from '@/service/label';

@Component
export default class FaciltiyBatchLabelComponent extends
    FormDialogComponent<any> {
        private ids: Array<string>;
        public type: String;
        dialogOpen(data: any, service: ICRUDQ<any, any>, viewMode:ViewModeType):void {
            this.ids = data.selectedRowKeys;
            this.type = data['tagEntityType'];
            this.dialogVisible = true;
            this.viewMode = viewMode;
            this.dialogTitle = '批量添加标签';
            this.$nextTick(() => {
                if (this.jtlForm) {
                    const controlItem = {
                        key: 'tagIds',
                        label: '标签',
                        type: FormControlType.SELECT,
                        optionsPromise: LabelService.getAllLabel.bind(LabelService),
                        message: '添加标签，不覆盖原标签',
                        mode: 'multiple',
                        required: true
                    };
                    this.jtlForm.initFormByControls([controlItem]);
                }
            });
        }

        dialogOK(): Promise<any> {
            if (!this.jtlForm) {
                return;
            }
            return this.jtlForm.submitForm().then((ret) => {
                const params = {
                    entityIds: this.ids,
                    tagIds: this.jtlForm.formModel.tagIds,
                    relType: this.type
                };
                LabelService.batchSave(params).then(res => {
                    this.dialogClose();
                    this.$emit('dialogOK');
                });
            }).catch(err => {
                throw err;
            });
        }
}
