
import { FacilityAttributeEntityModel, FacilityAttributeQueryModel } from '@/entity-model/facility-attribute-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/facilitytype`;

class FacilityTypeAttributeService implements ICRUDQ<FacilityAttributeEntityModel, FacilityAttributeQueryModel> {
    async create(model: FacilityAttributeEntityModel):Promise<FacilityAttributeEntityModel> {
        const url = `${URL_PATH}/saveAttr`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityAttributeEntityModel> {
        const url = `${URL_PATH}/getAttrs/${modelId}`;
        const res = await get(url);
        return new FacilityAttributeEntityModel(true, null).toModel(res);
    }

    async update(model: FacilityAttributeEntityModel):Promise<FacilityAttributeEntityModel> {
        const url = `${URL_PATH}/saveAttr`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityAttributeEntityModel):Promise<FacilityAttributeEntityModel> {
        const url = `${URL_PATH}/delAttr/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: FacilityAttributeQueryModel):Promise<any> {
        // const params: any = query?.toService();
        const url = `${URL_PATH}/getAttrs/${(query as FacilityAttributeQueryModel).entityId}`;
        const res = await get(url);
        return _.map(res, item => item = new FacilityAttributeEntityModel(true, (query as FacilityAttributeQueryModel).entityId).toModel(item));
    }

    async syncFacilityAttribute(facilityTypeId: string):Promise<any> {
        const url = `${URL_PATH}/updateFacilityAttrs/${facilityTypeId}`;
        const res = await post(url);
        return res;
    }
}

export default new FacilityTypeAttributeService();
